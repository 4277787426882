import React, { Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const directions = (
  <Fragment>
    <h3>Start Location</h3>
    <p>Paekākāriki Community Gardens, north end of Tilley Road</p>

    <h3>Distance to Hut</h3>
    <p>200 metres</p>

    <h3>Your Directions</h3>

    <p>
      You’ll find the entrance to the Community Gardens at the north end of
      Tilley road. There is ample parking here next to the sports field. Walk up
      the rise, keeping to the left, through the gardens and down and under the
      large pine tree. Keep going to the right of the blackberry patch until you
      reach overgrown patch of tall fennel, forge your way through here a few
      metres, until you spy the hut.
    </p>
    <p>
      Alternatively, you can walk down the track that runs alongside the gardens
      and enter after the blackberry patch.
    </p>

    <h3>Nearest Train Station</h3>
    <p>1.4km from Paekākāriki Station</p>

    <h3>Safety Notes</h3>

    <p>
      You’ll come across uneven ground and many trip hazards, stick to the mown
      pathways. Go slow and help each other, watch out for blackberry. Be sun
      smart, the hut is in a sunny spot with overgrown grasses which could be an
      allergy risk. Be careful not to trample any vegetables or fruit trees as
      you walk through the gardens. Take only photos and leave only footprints.
    </p>

    <p>
      <OutboundLink
        href="https://drive.google.com/open?id=1gT3zmqLtTor48xaApayAO2Mgh7YNu_f0"
        target="_blank"
        rel="noopener noreferrer"
        className="big-link"
      >
        Download for offline use
      </OutboundLink>
    </p>
  </Fragment>
)

export default directions
