import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Audio from "../../../components/audio/audio"
import hutAudio from "./hut-audio.mp3"
import gif from "./fictionGif.mp4"

const hutFiction = (
  <>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>
    <div className="u-side-pad u-bottom-pad">
      <h3 className="heading--big">
        The Whales Tale (memories from the Parrot's Perch)
      </h3>
      <h4 className="heading--small">By Apirana Taylor</h4>
      <p>
        The nor’wester howled. Shrieking at the sky. Moaning and groaning across
        the strait lashing the trees and hills. Raindrops bigger than golf balls
        blitzed the roof and pelted the windows as our house shook in the gale.
        Hail, sleet and ice dashed themselves into the earth like spears.
        Lightning cracked, thunder boomed as the wind and sea whirled about arm
        in arm in an insane dance flaying their skirts of foam, salt and sea
        spray along the coast as the sea went mad in the night.
      </p>
      <p>
        The next morning the wind, rain and sleet fled, but the sea raged on
        continuing its assault on the land. Waves like mountains thundered in.
        The briny was a dirty brown sludge. Between the giant rollers, swells
        heaved to and fro swept this way and that by a mighty arm. The eddies,
        hiss and suck of the turbulent tide turned the bay into a giant turgid
        washing machine full of water swishing around and around. In this
        maelstrom, a mighty galleon or liner weighing a thousand tons would have
        been tossed about like a lightweight cork without a bottle.
      </p>
      <p>
        The sea, like life, has many moods, depths, shallows and rocky pools.
        Here on the edge of the coast where the wind whipped scrub, clings for
        its life to the steep scraggy hillsides, the sea can lie flat as a
        mirror or gently roll in sun kissed curlers as the surfers wipe out.
        Boogie boarders jet in on the back of playful waves. The sun paints
        bathers, autumn summer gold, as whanau, friends and children bobble
        about in the water splashing and squealing with delight.
      </p>
      <p>
        ‘Dad dad,’ squeaked two of my daughters that morning after the storm, as
        I surveyed the mauled scenery, from behind the shelter of our salt
        frosted sitting-room window. The village was drenched and looked
        scrubbed. The roads were wet and shiny and the puddles glistened and
        sparkled morning light.
      </p>
      <p>
        ‘Dad,’ squealed my youngest daughter. ‘Dad,’ repeated her older sister.
        ‘Some whales have been washed ashore.’
      </p>
      <p>‘Whales?’</p>
      <p>‘Yes whales,’ called the children as they danced about the room.</p>
      <p>‘We better go and dig them out,’ I said. </p>
      <p>
        I’d recently seen a pod of Pilot whales stranded up north and the locals
        dug them out. ‘Get some spades and a wheelbarrow,’ I called as the
        youngsters bolted out the door.
      </p>
      <p>
        My wife, our third and eldest daughter and my son joined us as we loaded
        up the barrow with two shovels and some rope. ‘We’ll get em out don’t
        worry,’ I said. We made our way down the drive out onto the street and
        headed for the beach.
      </p>
      <p>
        We love it here. In late winter and early spring purple ragwort daisies
        and wild sweet pea coat the hills behind the village. They coat the
        hillsides with browns, greens and greys, with textures and tones like a
        giant mural. The sunsets can be muted or flaming and brilliant. Each
        sundown is different. There is a poem and a painting in every dusk.
      </p>
      <p>
        When the pohutukawa trees blossom they tell us summer is on the way. The
        seasons often don’t come and go gracefully here. Winter gives autumn a
        hiding. Then spring and winter dance in the wind and fight for dominance
        before spring boots winter out the door.
      </p>
      <p>
        On this morning spring hadn’t quite won. A muted sun was up but the sea
        hadn’t given up the fight. The ocean tossed itself around like a taniwha
        roaring and smashing itself about, frothing at the mouth.
      </p>
      <p>
        We weren’t the only ones intent on digging the whales out. Word spreads
        fast in Paekakariki. In a moment the merest murmur or whisper at one end
        of the bay develops into a hurricane at the other in less time than it
        takes to say, ‘sea spray.’ Paekakariki means the parrot's perch which
        often seems apt.
      </p>
      <p>
        Along the waterfront and down the streets leading to the parade, a
        kaleidoscope of people of all ages, shapes, sizes and colours trundled
        down to the beach to see and save the whales. There was a convoy of
        trolleys, wheelbarrows and carts loaded with picks, spades, shovels, a
        pram and anything else people thought might help, being pushed along
        towards the southern end of the beach.
      </p>
      <p>
        Paekakariki 1996 was a diverse community bordered in by the railway
        tracks, Queen Elizabeth Park at one end and the sea which crashes into
        centennial highway to the South. Sometimes we disagree. We live in an
        enclosed community, washed over by the comings and goings of many
        different people like the flow of tides.
      </p>
      <p>
        As we made our way along the beach we saw the whales. Three of them. As
        we neared the sands where they’d stranded the whales got bigger and
        bigger and bigger.
      </p>
      <p>
        Wind gusts hurled themselves at us and hushed our gaping mouths as these
        titans thrashed about in the roil. My son ran towards them. Some of us
        slowed down. We looked at one another and then back to the drama, as
        step by step with wheel barrows, spades and shovels we neared this
        colosol fight of life and death.{" "}
      </p>
      <p>
        We realised picks, wheelbarrows and shovels wouldn’t do it. We may as
        well have tried to dig these denizens of the deep out with toothpicks.
      </p>
      <p>
        Waves thundered in. The surge and swell in the gulf between the rollers
        hissed and roared. It sucked and blew the whales in as if they were
        pumice or flimsy lightweight bits of driftwood. These three behemoths,
        two adults and a calf were helpless. What could we do.
      </p>
      <p>
        Never had any of us seen such a sight. I knelt down by my daughters and
        viewed the whales as they saw them. The leviathans blocked out the sun
        with their mighty tails that thrashed about. From time to time they
        rested from their labours We could get close enough to eye the whales
        eye to eye and see a very intelligent life looking back at us.
      </p>
      <p>
        There are sweet memories of life here. Once a tui copied the sound of a
        mobile phone and sang like one for several years throughout the village.{" "}
      </p>
      <p>
        The pohutukawa trees in summer are resplendent. They are our country’s
        flowering Christmas tree.{" "}
      </p>
      <p>
        Native bird life seems to be increasing. The acrobatic tui and plump
        kereru grace the sky more often. They feast on the flax buds, orange
        karaka berries, red pohutukawa flowers and kowhai. Some birds wing their
        way over from Kapiti, the island that sleeps off the coast of our eye.
      </p>
      <p>
        On the train out of Wellington the hustle and bustle slips from our
        shoulders as we ride through the cutting at Pukerua Bay and the view
        pans into a wide expanse with the great bowl of sea far below.{" "}
      </p>
      <p>
        With Kapiti in the distance, the lure of Te Waipounamu across the strait
        beckons, as we North Islanders look out from the shores of the main land
        across the water to the South Island.
      </p>
      <p>
        Friends are lifelong, live close by and are seen daily in Paekakariki
        and so we feel their absence when they finally set sail across the great
        ocean or simply move on.
      </p>
      <p>
        What is there to learn from this memory of the whales and their battle
        against the furies. A thousand bulldozers couldn’t haul them out. What
        is this and why, I asked myself. Our children stood aghast or ran about.
        Dogs scampered to and fro, or sat and watched as if puzzled by this
        struggle.{" "}
      </p>
      <p>
        We couldn’t even kill the whales to put them out of their misery as they
        struggled in their attempt to thwart the sea that drove them in.
      </p>
      <p>
        Harpoon or rifle shot couldn’t do it. I stood close to one of the whales
        and when I looked up felt what an insignificant atom of life I was.{" "}
      </p>
      <p>
        The history of Paekakariki is like the wind in the hills. There have
        been battles churning the sea red as blood. The navigator Kupe named
        Kapiti centuries ago. Waka.. sailing ships and times when whalers were
        seen. The name Te Rauparaha the mighty chief sings in the winds of
        history.{" "}
      </p>
      <p>
        Once when pods of whales on migration swam between Kapiti and the beach
        you could cross from shore to the island on their backs. Sometimes orca
        or dolphins cruise between the shore and island.{" "}
      </p>
      <p>
        There is the true and epic saga of the twins Florrie and Pearl, two
        women nearly eighty, who found themselves challenged in a nearby paddock
        by a mad bull. Pearl patted the bull on the nose and told him he was ‘a
        lovely boy,’ The bull didn’t appreciate that and instigated a prolonged
        pursuit and attack, tossing Florrie into the air. With understated cool
        kiwi calm and grit under pressure Pearl and Florrie turned and defeated
        the bull with the assistance of a young nimble footed boy, a Maori toa,
        a warrior, who was armed with a broom which he wielded as a taiaha.
      </p>
      <p>
        Jandals, footy shorts, old singlets or tatty rugby jerseys were the norm
        for everyday wear and special occasions in the village, which was once a
        workers railway town. I’ve met many bright people in Paekakariki who
        inspire me in many ways and who opt out of the system which is a train
        ride away.{" "}
      </p>
      <p>
        It’s easy to romanticise and say this was the best of old kiwi towns.
        Quite a few Paekakariki homes were once propped up by bits of driftwood
        and sea shells. They were picturesque and looked as if the wind, sea,
        sun and salt had permanently blasted most of the paint off them. They
        have been bulldozed out of existence and replaced by mansions.
      </p>
      <p>
        Paekakarikians often reveal a dry sense of humour which is why many of
        the towns’ characters had an undying thirst and felt the need to spend a
        lot of time in the local pub propping up the bar and solving the world’s
        problems. This is the parrot's perch but surely the perch wasn’t meant
        to be a bar stool. The pub was a dump but it had character like the old
        timers within it. Sadly like the batches, ‘ye old Paekakariki Pub,’ was
        bulldozed out of existence.{" "}
      </p>
      <p>
        Sweet memories slip through our fingers. We live in a constant state of
        flux. Once many of us here seldom had a coin or two to rub together. Now
        our young hoping to invest in the future, to buy a house, to make a
        start, to raise a whanau, struggle to do so.
      </p>
      <p>What does the whales tale say?</p>
      <p>
        The tides had driven the calf into the shallows and the adult whales
        came in to try and turn their young one around and save it. They failed
        and all three whales were doomed.{" "}
      </p>
      <p>
        These giant children of the sea float easily on water but once driven
        ashore they couldn’t support their own body weight. Their tonnage
        weighed down and gradually, like air forced out of giant bellows,
        crushed the life breath out of them. Time slowed down that morning as
        the struggle for life over death revealed itself to us in a gigantic
        slow motion panorama. Foam flew, flukes spumed. Whale blood flowed.
        Tails flapped and smashed with thunderous thwacks on the water. The
        whales called to us. They groaned moaned and sighed. It took time for
        them to die.
      </p>
      <p>
        Why, I asked and received a lung full of sand gusted into my mouth for
        an answer.
      </p>
      <p>
        The media buzzed around. Out of towners came to assist. Many of us,
        young and old of different colours and cultures worked together. We
        forgot our differences and pitched in, together, armed with little more
        than our hearts, in an attempt to help.
      </p>
      <p>
        I walked about with a pointless length of rope in my hand. We grafted
        away. We tried and gave it our best.
      </p>
      <p>
        The shoreline recedes, the beach is getting smaller. Giant boulders have
        been dumped along the coast to stop the ocean from grabbing hunks of
        road and washing it away. There are rising water levels overloaded with
        plastic. Attempts at restoration and replanting are evident.
      </p>
      <p>
        Honour and respect of the title tangata whenua on this part of the coast
        should first be given to Ngati Haumia. The saying is ...
      </p>
      <blockquote>
        <p>
          Tainui te waka
          <br />
          Pouawha me Wainui nga maunga
          <br />
          Wainui te awa
          <br />
          Raukawa te moana
          <br />
          Kapiti te motu
          <br />
          Ngati Toa Rangatira te iwi
          <br />
          Ngati Haumia te hapu
          <br />
          Haumia whakatere taniwha te tangata
          <br />
          Mutu Mira te whanau whanui
        </p>

        <p>
          Tainui is the waka
          <br />
          Pouawha and Wainui are the mountains
          <br />
          Wainui is the river
          <br />
          Raukawa is the sea
          <br />
          Kapiti is the island
          <br />
          Ngati Toa Rangatira is the iwi
          <br />
          Ngati Haumia is the clan
          <br />
          Haumia Whakatere Taniwha is the ancestor
          <br />
          Mutu mira is the family
        </p>
      </blockquote>
      <p>
        There are also the sage like words, ‘mehemea he potae ta kapiti ka ua.’
        If Kapiti wears a hat, clouds on the peak, it will rain tomorrow.
      </p>
      <p>
        Paekakariki is like the Nashville of New Zealand without the glitz.
        There are as many different good guitarists and musicians here as there
        are grains of sand on the beach and wonderful artists.
      </p>
      <p>
        Many make an effort to support local initiatives such as the local
        primary school, playcentre, tennis club, the bowler, library and native
        restoration work.
      </p>
      <p>
        Our halls of entertainment are filled with what we conjure up. Often
        this is a variety of energetic political debates over local issues. At
        such time ability to transcend the heights in terms of simile, metaphor,
        hyperbole, and powerful colourful epithets hurled at the opposition like
        bricks excels that of the world’s greatest orators. Despite this some of
        us are known to fall asleep at such meetings. One resident suggested we
        should put a sign up at the entrance to the village saying, ‘Welcome to
        Paekakariki. We protest.’
      </p>
      <p>
        The hall is often used for art in all its forms, and wonderful concerts
        accompanied with mountains of sausage rolls tomato sauce and an adequate
        amount of suitable revitalising liquid refreshment.
      </p>
      <p>
        The whales were buried in the sand long ago. The wind wafted the smell
        of putrid blubber about the village for weeks. Years later the tides
        washed up some of the bones and scattered them about.
      </p>
      <p>
        We become salt living here on this thin wedge of coastline snickered in
        between the hills and ocean. No matter whether we live with the sea or
        try to fight it. The sea wins.
      </p>
      <p>The sun sets with a poem every day.</p>
      <blockquote>
        <p>
          <strong>home</strong>
        </p>
        <p>
          rain washed
          <br />
          sun blessed
          <br />
          wind swept
          <br />
          secret kept
          <br />
          village on the <br />
          lip of the sea
          <br />
          calm and blue
          <br />
          laced with waves
          <br />
          or stormy Paekakariki
          <br />
          people in Kapiti
          <br />
          southern isle
          <br />
          on the edge
          <br />
          of my eye
          <br />
          morning sky
          <br />
          train ride
          <br />
          from the city
          <br />
          sunsets like
          <br />
          jewels home <br />
          for me
        </p>
      </blockquote>
      <p>
        <OutboundLink
          href="https://drive.google.com/open?id=1jJB_YGJ2uzzAeglgWnU1g7eyfVvDLDRs"
          target="_blank"
          rel="noopener noreferrer"
          className="big-link"
        >
          Download ‘The Whales Tale’ by Apirana Taylor
        </OutboundLink>
      </p>
      <Audio audio={hutAudio} />
    </div>
    <div className="about-the-author u-side-pad">
      <h3 className="heading--small about-the-author__heading">
        About the author
      </h3>
      <p>
        Apirana Taylor - Ngati Porou, Te Whānau ā Apanui, Ngati Ruanui, Te Āti
        Awa, is a nationally and internationally published poet, playwright,
        short story writer, novelist, actor, painter and musician. He has been
        Writer in Residence at Canterbury and Massey Universities. He frequently
        tours nationally and internationally visiting schools, tertiary
        institutions and prisons reading his poetry, storytelling and taking
        creative writing workshops. He has written six collections of poetry, a
        book of plays, three collections of short stories and two novels. His
        work has been included in many national and international anthologies.
      </p>
    </div>
  </>
)

export default hutFiction
