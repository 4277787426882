import React, { Fragment } from "react"
import Layout from "../../../components/layout/layout"
import directionsContent from "../../../components/hut-data/hut1/directions"
import hutFictionContent from "../../../components/hut-data/hut1/hutFiction"
import aboutContent from "../../../components/hut-data/hut1/about"
import HutPageLayout from "../../../components/hutPageLayout/hutPageLayout"
import HutMap from "../../../components/hutMap/hutMap"
import { AccordionContent } from "../../../components/accordion/accordion"
import { GeolocationContext } from "../../../AppState"
import hutTitle from "../../../components/hut-data/hut1/hut-title.svg"
import doodle from "../../../components/hut-data/hut1/hut-doodle.jpg"
import aboutImg from "../../../components/hut-data/hut1/about-img.jpg"

const accordionContent: AccordionContent[] = [
  {
    title: "Directions",
    inner: (
      <Fragment>
        <img src={doodle} className="width-full" alt="" />
        <div className="u-side-pad u-top-pad">{directionsContent}</div>
      </Fragment>
    ),
    backgroundColour: "#fae297",
  },
  {
    title: "Found it",
    backgroundColour: "#ffcc99",
  },
  {
    title: "Hut fiction",
    inner: hutFictionContent,
    backgroundColour: "#d0dce2",
    locked: true,
  },
  {
    title: "About",
    inner: (
      <Fragment>
        <img src={aboutImg} alt="" className="width-full" />
        {aboutContent}
      </Fragment>
    ),
  },
]

function Hut() {
  return (
    <Layout title="Paekākāriki Hut">
      <GeolocationContext.Consumer>
        {context => {
          const { foundHuts } = context
          return (
            <div className="map__info-container">
              <HutMap
                isHutPage
                height="320px"
                foundHuts={foundHuts}
                bounds={[
                  [174.95432912636034, -40.97214824605697],
                  [174.96585416542825, -40.97753481253416],
                ]}
              />
            </div>
          )
        }}
      </GeolocationContext.Consumer>
      <HutPageLayout
        hutId="hut1"
        heading="Paekākāriki Hut"
        headingImg={hutTitle}
        headingColour="#336699"
        description="A hut constructed of local character; forging a space for community. Give or take, over time we all grow into something greater."
        accordionContent={accordionContent}
        unlockCode="R3TREAT"
      />
    </Layout>
  )
}

export default Hut
